import React from 'react';
import { Utils } from '../helpers/utils';
import LaddaButton, { XL, SLIDE_UP } from '@zumper/react-ladda';


interface HBFButtonProps {
    type?: 'solid'|'outline'|'link';
    size?: 'tiny'|'small'|'large'|'fullwidth';
    color?: 'primary'|'secondary'|'info'|'success'|'maternity'|'warning'|'danger';
    disabled?: boolean;
    isLoading?: boolean;
    onClick?: (evt: React.MouseEvent<HTMLButtonElement>) => void;
    children: any;
    className?: string;
}


export default function HBFButton(props: HBFButtonProps) {

    function handleClick(evt: React.MouseEvent<HTMLButtonElement>): void {
        if (props.onClick) {
            props.onClick(evt);
        }
    }

    const type = props.type || 'solid';
    const size = props.size || 'small';
    const color = props.color || 'primary';

    const cssClass = `${Utils.guardedTrim(props.className)} hbf-btn hbf-btn--${type} ${color} hbf-btn--${size}`;

    return (

        <LaddaButton
            loading={props.isLoading}
            disabled={props.disabled}
            onClick={handleClick}
            data-color="#eee"
            data-size={XL}
            data-style={SLIDE_UP}
            data-spinner-size={30}
            data-spinner-color="#ddd"
            data-spinner-lines={12}
            className={cssClass}
        >{props.children}</LaddaButton>

    );
}
