import React from 'react';
import DownloadButton from './DownloadButton';

interface TopButtonsProps {
    downloadLoading?: boolean;
    downloadDisabled?: boolean;
    onDownloadClick: () => void;
}


export default function TopButtons(props: TopButtonsProps) {
    return (
        <div className="EmployeesButtons left">
            <DownloadButton isLoading={props.downloadLoading} disabled={props.downloadDisabled} 
                onClick={() => props.onDownloadClick()} />            
        </div>
    );
}
