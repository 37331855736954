import React from 'react';
import Form from 'react-bootstrap/Form';

interface LoginDetailsProps {
    dirty: {[name: string]: boolean};
    error: {[name: string]: string};
    onChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur: (evt: React.FocusEvent<HTMLInputElement>) => void;
    onEnter: (evt: React.KeyboardEvent<HTMLInputElement>) => void;
}

export default function LoginDetails(props: LoginDetailsProps) {

    function handleKeyPress(evt: React.KeyboardEvent<HTMLInputElement>) {
        if (evt.key === 'Enter') {
            evt.preventDefault();
            props.onEnter(evt);
        }
    }


    return (
        <Form>
            <Form.Group controlId="formId">
                <Form.Label>ID</Form.Label>
                <Form.Control 
                    name="id"
                    type="text" 
                    autoComplete="off"
                    placeholder="Enter company ID" 
                    isValid={props.dirty.id && !props.error.id}
                    isInvalid={props.dirty.id && !!props.error.id}
                    onChange={props.onChange} 
                    onBlur={props.onBlur}
                    autoFocus={true}
                />
                <Form.Control.Feedback type="invalid">
                    {props.error.id}
                </Form.Control.Feedback>  
            </Form.Group>
            <Form.Group controlId="formPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control 
                    name="password"
                    type="password" 
                    placeholder="Password" 
                    isValid={props.dirty.password && !props.error.password}
                    isInvalid={props.dirty.password && !!props.error.password} 
                    onChange={props.onChange} 
                    onBlur={props.onBlur}
                    onKeyPress={handleKeyPress}
                />
                <Form.Control.Feedback type="invalid">
                    {props.error.password}
                </Form.Control.Feedback>                      
            </Form.Group>
        </Form>
    );
}
