function tokenLength(): number {
    let result = 8;
    if (window != null && typeof window.corpPortalConfig === 'object' && typeof window.corpPortalConfig.tokenLength === 'number') {
        result = window.corpPortalConfig.tokenLength;
    }
    return result;
}

function autoSaveIdleMs(): number {
    let result = 5000;
    if (window != null && typeof window.corpPortalConfig === 'object' && typeof window.corpPortalConfig.autoSaveIdleMs === 'number') {
        result = window.corpPortalConfig.autoSaveIdleMs;
    }
    return result;
}

const configHelper = {
    autoSaveIdleMs,
    tokenLength
};

export default configHelper;
