import React from 'react';
import Table from 'react-bootstrap/Table';
import { EmployeeModel } from '../../models/EmployeeModel';

interface EmployeesTableProps {
    employees: EmployeeModel[];
    onIsCurrentEmployeeChange: (evt: React.ChangeEvent<HTMLInputElement>, id: string) => void;
    onCommentChange: (evt: React.ChangeEvent<HTMLInputElement>, id: string) => void;
}


export default function EmployeesTable(props: EmployeesTableProps) {

    function renderTableRows({employees, onIsCurrentEmployeeChange, onCommentChange}: EmployeesTableProps) {

        return employees.map(employee => {
            const { id, lastName, firstName, payrollID, employeeEmail, employeeYN, comments } = employee;
            return (
                <tr key={id}>
                    <td>{payrollID}</td>
                    <td>{firstName}</td>
                    <td>{lastName}</td>
                    <td>{employeeEmail}</td>
                    <td>
                        <input
                            type="checkbox"
                            defaultChecked={employeeYN === true}
                            onChange={(event) => onIsCurrentEmployeeChange(event, id)}
                        />
                    </td>
                    <td>
                        <input
                            className="employeeTableTextInput"
                            type="text"
                            defaultValue={comments}
                            onChange={(event) => onCommentChange(event, id)}
                        />
                    </td>
                </tr>
            );
        });
    }

    
    return (
        <Table striped={true} bordered={true} hover={true} size="sm" className="EmployeesTable">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Work Email</th>
                    <th>Current Employee</th>
                    <th>Comments</th>
                </tr>
            </thead>
            <tbody>
                {renderTableRows(props)}
            </tbody>
        </Table>
    );

}
