import configHelper from './configHelper';

function memberNumber(value: any, name?: string): string {
    if (typeof value === 'string' && /^\d{8}$/.test(value)) {
        return '';
    }
    const guardedName = name || 'Company ID';
    return `${guardedName} should be 8 digits`;
}

function token(value: any): string {
    const tokenLength = configHelper.tokenLength();
    if (typeof value === 'string') {
        const validateRE = new RegExp('^\\d{' + tokenLength + '}$');
        if (validateRE.test(value)) {
            return '';
        }
    }
    return `Token should be ${tokenLength} digits`;
}


const Validate = {
    memberNumber,
    token
};

export default Validate;
