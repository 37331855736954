import React from 'react';
import HBFButton from '../../components/HBFButton';

interface LoginButtonProps {
    onClick: (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    text?: string;
    isDisabled?: boolean;
    isLoading?: boolean;
}

export default function LoginButton(props: LoginButtonProps) {
    return (
        <HBFButton className="LoginButton" onClick={props.onClick} isLoading={props.isLoading} disabled={props.isDisabled}>
             {props.text || 'Login'}
        </HBFButton>
    );
}
