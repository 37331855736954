import { AuthService } from './AuthService';
import { TypeCheck } from '../helpers/typecheck';

export enum BadResponse { 
    OK = '',
    Null = 'Null', 
    Unauthorised = 'Unauthorised', 
    Error = 'Error' 
}

async function fetchWithCredentials(url: string, options?: any): Promise<Response|BadResponse|null> {
    const user = AuthService.currentUser();
    if (!TypeCheck.isAuthResponse(user)) {
        console.error('fetchWithCredentials: not logged in');
        return Promise.resolve(BadResponse.Unauthorised);
    }
    options = options || {};
    options.headers = options.headers || {};
    options.headers['Authorization'] = 'Bearer ' + user.token;
    const response = await fetch(url, options);
    if (response.ok) { // Auth is good, return the response.
        return response;
    }

    if (response.status === 401) {
        try {
            // JWT has expired.
            await AuthService.refreshAuth();
        } catch {
            console.error('fetchWithCredentials: not logged in');
            return Promise.resolve(BadResponse.Unauthorised);
        }
        return fetchWithCredentials(url, options); // Repeat the original request
    } else { // Status is not 401 and/or there's no Token-Expired header
        return response; // Return the original 401 response
    }
}

function guardedParse<T>(response: Response|BadResponse|null, parser?: (r: Response) => Promise<any>): Promise<T | BadResponse> {
    if (response == null) {
        throw new Error(BadResponse.Null);
    }
    if (typeof response === 'string') {
        throw new Error(response);
    }
    if (response.ok) { // All good, keep going.
        if (parser == null) {
            return response.json();
        }
        return parser(response);
    }
    if (response.status === 401) {
        throw new Error(BadResponse.Unauthorised);
    }  
    return response.json().then(data => {
        if (data == null) {
            throw new Error(BadResponse.Null);
        }
        if ('message' in data) {
            const error = new Error(data.message);
            if ('severity' in data) {
                error.name = data.severity;
            }
            throw error;
        }   
        if (typeof data === 'string') {
            throw new Error(data);
        }  
        throw new Error(BadResponse.Error);
    });
}


export const FetchService = {
    fetchWithCredentials,
    guardedParse
};

