import React from 'react';
import logo from '../assets/logo_no_tagline.png';
import AppState from '../state/AppState';
import { navigate } from '@reach/router';
import { Routes } from '../helpers/routes';
import './Header.scss';
import { AuthResponse } from '../models/AuthResponse';
import logout from '../helpers/logout';

export default function Header() {
    function onClick(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, route: string) {
        e.preventDefault();
        if (route === Routes.login) {
            store.set('user')(null);
        }
        navigate(route);
    }

    async function logoutClick(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, userAuthResponse: AuthResponse) {
        e.preventDefault();
        try {
            await logout(store);
        } catch (ex) {
            console.error('logoutClick ERROR', ex);
        } finally {
            navigate(Routes.login);
        }
    }


    const store = AppState.useStore();
    const online = store.get('online');
    const user = store.get('user');

    return (

        <div className="header">
            {online || (
                <span className="header__offline"><a href={Routes.contact}
                   onClick={(evt) => onClick(evt, Routes.contact)}>offline</a></span>
            )}
            <img src={logo} alt="HBF Logo" className="header__logo" />
            <span className="header__heading-container"><h1 className="header__heading-header">Corporate Portal</h1></span>
            <div className="header__nav">
                {!user &&
                    <>
                        <a className="header__nav-item" href={Routes.login} onClick={(evt) => onClick(evt, Routes.login)}>Login</a>
                    </>
                }
                {user &&
                    <>
                        <a className="header__nav-item" href={Routes.employees}
                           onClick={(evt) => onClick(evt, Routes.employees)}>Employees</a>
                    </>
                }
                <a className="header__nav-item" href={Routes.contact} onClick={(evt) => onClick(evt, Routes.contact)}>Contact</a>
                {user &&
                    <>
                        <a className="header__nav-item" href={Routes.login} onClick={(evt) => logoutClick(evt, user)}>Logout</a>
                    </>
                }
            </div>
        </div>
    );
}
