import React from 'react';
import HBFButton from '../../components/HBFButton';

interface TokenLoginButtonProps {
    onClick: (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    text?: string;
    disabled?: boolean;
    isLoading?: boolean;
}

export default function TokenLoginButton(props: TokenLoginButtonProps) {
    return (
        <HBFButton className="TokenLoginButton" onClick={props.onClick} isLoading={props.isLoading} disabled={props.disabled}>
             {props.text || 'Login'}
        </HBFButton>
    );
}
