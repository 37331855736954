import React from 'react';
import { BaseScreenProps } from '../../models/BaseScreenProps';
import StatefulLoginDialog from './LoginDialogue';

type LoginPageProps = BaseScreenProps;
interface LoginPageState {
    id: string;
    password: string;
    isValidLogin: boolean;
}
export default class LoginPage extends React.Component<LoginPageProps, LoginPageState> {
    constructor(props: LoginPageProps) {
        super(props);
        this.state = {
            id: '',
            password: '',
            isValidLogin: false
        };
    }

    render() {
        return (
            <div className="LoginPage">
                <StatefulLoginDialog />
            </div>
        );
    }

}

