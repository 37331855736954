import { AuthResponse } from '../models/AuthResponse';
import { ToastModel } from '../models/ToastModel';
import MessageResponse from '../models/MessageResponse';
import LoginResponse from '../models/LoginResponse';
import Severity from '../models/Severity';

// Returns true if the object has all the props specified.
function objContains(obj: any, props: string[]) {
    if (obj == null) {
        return false;
    }
    for (const prop of props) {
        if (!(prop in obj)) {
            return false;
        }
    }
    return true;
}


function objContainsTypes(obj: any, propTypes: {[name: string]: string}) {
    const propNames = Object.keys(propTypes);
    if (!objContains(obj, propNames)) {
        return false;
    }
    for (const key of propNames) {
        if (typeof obj[key] !== propTypes[key]) {
            return false;
        }
    }
    return true;    
}


// Tests if an object looks like an AuthResponse.
function isAuthResponse(obj: any): obj is AuthResponse {
    return objContainsTypes(obj, {'memberNumber': 'string', 'token': 'string', 'refreshToken': 'string'});
}

// Test if an object looks like a ToastModel.
function isToastModel(obj: any): obj is ToastModel {
    return objContainsTypes(obj, {'message': 'string', 'type': 'string'});
}

// Test if an object looks like a MessageResponse.
function isMessageResponse(obj: any): obj is MessageResponse {
    return objContains(obj, ['message']);
}

// Test if an object looks like a Error.
function isError(obj: any): obj is Error {
    return objContains(obj, ['message', 'name']);
}

// Test if an object looks like a LoginResponse.
function isLoginResponse(obj: any): obj is LoginResponse {
    return objContainsTypes(obj, {'passwordReset': 'boolean', 'success': 'boolean', 'message': 'string'});
}

function isSeverity(obj: any): obj is Severity {
    return (obj === 'notice' || 
        obj === 'info' || 
        obj === 'warning' || 
        obj === 'error');
}

export const TypeCheck = {
    isAuthResponse,
    isError,
    isLoginResponse,
    isMessageResponse,
    isSeverity,
    isToastModel
};
