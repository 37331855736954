import AutoSave from './autosave';
import { LoginService } from '../services/LoginService';
import { IAppState } from '../state/AppState';
import { Store } from 'undux';

// Common logout actions.
const logout = async (store: Store<IAppState>) => {
    try {
        const user = store.get('user');
        await AutoSave.fireLogoutCheck();
        AutoSave.loggedOut();
        if (user != null) {
            await LoginService.logout(user.memberNumber);
        }
    } catch (ex) {
        console.error('logout ERROR', ex);
    } finally {
        store.set('user')(null);
    }
};

export default logout;
