type AutoSaveLogoutSubscriber = () => Promise<void>;
type AutoSaveDebounceFn = () => void;

// AutoSave is a singleton that handles
// * auto save debouncing when editing employees - automatically save after user is idle
// * pub/sub events to enable auto saving when logging out.
class AutoSave {

    private _subscribers: AutoSaveLogoutSubscriber[] = [];
    public isEnabled = true;

    debounce = (func: AutoSaveDebounceFn, waitMs: number) => {
        const parent = this;
        let timeoutId = 0;
        return function executedFunction() {
            const later = () => {
                clearTimeout(timeoutId);
                if (parent.isEnabled) {
                    func();
                }
            };
            clearTimeout(timeoutId);
            timeoutId = window.setTimeout(later, waitMs);
        };
    }

    async fireLogoutCheck() {
        for (const subscriber of this._subscribers) {
            await subscriber();
        }
    }

    subscribeLogoutCheck(subscriber: AutoSaveLogoutSubscriber) {
        this._subscribers.push(subscriber);
    }

    loggedOut() {
        this.isEnabled = false;
        this._subscribers = [];
    }

    loggedIn() {
        this.isEnabled = true;
    }

}


const autoSaveSingleton = new AutoSave();

export default autoSaveSingleton;
