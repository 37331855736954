import { IAppState } from '../state/AppState';
import { Store } from 'undux';
import { TypeCheck } from './typecheck';

function setToast(appState: Store<IAppState>, type: string, message: string, delayMs?: number) {
    if (type == null) {
        type = 'error';
    }
    type = type.toLowerCase();
    if (TypeCheck.isSeverity(type)) {
        appState.set('toast')({type, message, delayMs});
    } else {
        appState.set('toast')({type: 'error', message, delayMs});
    }
}

const ToastUtils = {
    set: setToast,
    notice: (appState: Store<IAppState>, message: string, delayMs?: number) => {
        setToast(appState, 'notice', message, delayMs);
    },
    info: (appState: Store<IAppState>, message: string, delayMs?: number) => {
        setToast(appState, 'info', message, delayMs);
    },
    warning: (appState: Store<IAppState>, message: string, delayMs?: number) => {
        setToast(appState, 'warning', message, delayMs);
    },
    error: (appState: Store<IAppState>, message: string, delayMs?: number) => {
        setToast(appState, 'error', message, delayMs);
    }
};

export default ToastUtils;
