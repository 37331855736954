import React from 'react';
import { BaseScreenProps } from '../../models/BaseScreenProps';
import StatefulTokenLoginDialog from './TokenLoginDialogue';

type TokenLoginPageProps = BaseScreenProps;
interface LoginPageState {
    id: string;
    password: string;
    isValidLogin: boolean;
}
export default class LoginPage extends React.Component<TokenLoginPageProps, LoginPageState> {
    constructor(props: TokenLoginPageProps) {
        super(props);
        this.state = {
            id: '',
            password: '',
            isValidLogin: false
        };
    }

    login() {

    }


    render() {
        return (
            <div className="LoginPage">
                <StatefulTokenLoginDialog onSuccessfullLogin={this.login}/>
            </div>
        );
    }

}

