import { createConnectedStore, Effects, Store } from 'undux';
import { AuthService } from '../services/AuthService';
import { AuthResponse } from '../models/AuthResponse';
import { ToastModel } from '../models/ToastModel';

export interface IAppState {
    id: string | null;
    user: AuthResponse | null;
    toast: ToastModel | string | null;
    online: boolean;
}


export interface AppStateProps {
    store: Store<IAppState>;
}


const user = AuthService.currentUser();

const initialState = { id: null, user, toast: null, online: true };


const effects: Effects<IAppState> = state => {

    state
        .on('user')
        .subscribe(async userNewVal => {

            if (userNewVal != null) {
                AuthService.storeUser(userNewVal);       
            } else {
                AuthService.clearUser();     
            }

        });


    return state;
};


const appState = createConnectedStore<IAppState>(initialState, effects);


export default appState;
