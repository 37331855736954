import './PasswordPage.scss';
import React from 'react';
import { BaseScreenProps } from '../../models/BaseScreenProps';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import HBFButton from '../../components/HBFButton';
import ResetService from '../../services/ResetService';
import { Utils } from '../../helpers/utils';
import Toast from '../../helpers/ToastUtils';
import AppState, { AppStateProps } from '../../state/AppState';
import { TypeCheck } from '../../helpers/typecheck';
import PasswordStrengthBar from 'react-password-strength-bar';
import { Redirect } from '@reach/router';
import { Routes } from '../../helpers/routes';
import Validate from '../../helpers/validate';

type PasswordPageProps = AppStateProps & BaseScreenProps;
interface PasswordPageState {
    memberNumber: string;
    resetCode: string;
    newPassword: string;
    confirmPassword: string;
    setSuccess: boolean;
    dirty: {[name: string]: boolean};
}

class PasswordPage extends React.Component<PasswordPageProps, PasswordPageState> {
    constructor(props: PasswordPageProps) {
        super(props);
        this.state = {
            memberNumber: '',
            resetCode: '',
            newPassword: '',
            confirmPassword: '',
            setSuccess: false,
            dirty: {
                memberNumber: false,
                resetCode: false,
                newPassword: false,
                confirmPassword: false
            }
        };
    }

    componentDidMount() {
        const memberNumber = Utils.guardedTrim(this.props.store.get('id'));
        this.setState({memberNumber});
    }

    render() {

        if (this.state.setSuccess) {
            return <Redirect noThrow={true} to={Routes.login} />;
        }

        const passwordMatch = this.state.newPassword === this.state.confirmPassword;

        const memberNumberMessage = Validate.memberNumber(this.state.memberNumber);
        const valid = passwordMatch &&
            !memberNumberMessage &&
            this.state.resetCode &&
            this.state.newPassword &&
            this.state.confirmPassword;

        const errors = {
            memberNumber: memberNumberMessage,
            confirmPassword: passwordMatch ? '' : 'Passwords must match.'
        };

        return (
            <div className="main PasswordPage">
                <h2>Password reset</h2>
                <Alert variant="info">
                    Use the reset code that was emailed to you, and set a new password below.<br />
                    Don't know the code? <Alert.Link href="/reset">Request a new code here.</Alert.Link>
                </Alert>
                <Form>
                    <Form.Group controlId="memberNumber">
                        <Form.Label>Company ID</Form.Label>
                        <Form.Control type="text" placeholder="Enter company ID"
                            name="memberNumber"
                            value={this.state.memberNumber}
                            isValid={this.state.dirty.memberNumber && !memberNumberMessage}
                            isInvalid={this.state.dirty.memberNumber && !!memberNumberMessage}
                            required={true}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur} autoComplete="off"
                            autoFocus={!this.state.memberNumber}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.memberNumber}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="resetCode">
                        <Form.Label>Reset code</Form.Label>
                        <Form.Control type="password" placeholder="Enter the reset code"
                            name="resetCode"
                            isValid={!!(this.state.dirty.resetCode && this.state.resetCode)}
                            isInvalid={this.state.dirty.resetCode && !this.state.resetCode}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            required={true}
                            autoFocus={!!this.state.memberNumber}
                        />
                    </Form.Group>
                    <Form.Group controlId="newPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder="Enter a new password"
                            name="newPassword"
                            required={true}
                            isValid={!!(this.state.dirty.newPassword && this.state.newPassword)}
                            isInvalid={this.state.dirty.newPassword && !this.state.newPassword}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                        />
                        <PasswordStrengthBar password={this.state.newPassword} />
                    </Form.Group>
                    <Form.Group controlId="confirmPassword">
                        <Form.Label>Confirmed</Form.Label>
                        <Form.Control type="password" placeholder="Confirm the new password"
                            name="confirmPassword"
                            required={true}
                            isValid={!!(this.state.dirty.confirmPassword && this.state.confirmPassword && passwordMatch)}
                            isInvalid={this.state.dirty.confirmPassword && !(this.state.confirmPassword && passwordMatch)}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            onKeyPress={this.handleKeyPress}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.confirmPassword}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form>
                <HBFButton onClick={this.handleClick} disabled={!valid}>Reset</HBFButton>
            </div>
        );
    }


    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = Utils.guardedTrim(event.target.name);
        const value = event.target.value;
        if (!name || !(name in this.state)) {
            return;
        }
        this.setState((state) => {
            const newState: any = {[name]: value};
            return newState;
        });
    }


    handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const name = Utils.guardedTrim(event.target.name);
        if (!name || !(name in this.state)) {
            return;
        }
        if (event.target.value) {
            this.setState((state) => {
                const newState = { dirty: { ...state.dirty, [name]: true } };
                return newState;
            });
        }
    }


    handleClick = async () => {

        try {
            const result = await ResetService.setNewPassword(this.state.memberNumber, this.state.resetCode, this.state.newPassword);
            if (!TypeCheck.isMessageResponse(result)) {
                throw result;
            }
            if (!result.success) {
                this.showError(result.message);
            } else {
                this.setState({setSuccess: true});
                Toast.info(this.props.store, 'Your password was reset successfully. Try logging in again.');
            }
        } catch (ex) {
            const error = Utils.guardedTrim(ex);
            this.showError(`Error from server: ${error}`);
        }

    }


    handleKeyPress = async (evt: React.KeyboardEvent<HTMLInputElement>) => {
        if (evt.key === 'Enter') {
            evt.preventDefault();
            await this.handleClick();
        }
    }


    showError(reason?: string) {
        Toast.error(this.props.store, reason || 'Problem with setting the new password');
    }

}

const StatefulPasswordPage = AppState.withStore(PasswordPage);

export default StatefulPasswordPage;
