import React from 'react';
import AppState, { AppStateProps } from '../../state/AppState';
import LoginDetails from './LoginDetails';
import LoginButton from './LoginButton';
import { Routes } from '../../helpers/routes';
import { Utils } from '../../helpers/utils';
import { LoginService } from '../../services/LoginService';
import { navigate, Redirect } from '@reach/router';
import Toast from '../../helpers/ToastUtils';
import { TypeCheck } from '../../helpers/typecheck';
import Validate from '../../helpers/validate';
import configHelper from '../../helpers/configHelper';

type LoginDialogueProps = AppStateProps;
interface LoginDialogueState {
    id: string;
    password: string;
    validInput: boolean;
    nextRoute: string | null;
    dirty: {[name: string]: boolean};
}
class LoginDialogue extends React.Component<LoginDialogueProps, LoginDialogueState> {
    constructor(props: LoginDialogueProps) {
        super(props);
        this.state = {
            id: '',
            password: '',
            validInput: false,
            nextRoute: null,
            dirty: {
                id: false,
                password: false
            }
        };
    }

    render() {
        const nextRoute = this.state.nextRoute;
        if (nextRoute != null) {
            return <Redirect noThrow={true} to={nextRoute} />;
        }
        const input = this.getInput();
        const idMessage = Validate.memberNumber(input.id, 'ID');
        const loginDisabled = !!idMessage || !input.password;
        const dirty = this.state.dirty;
        const error = { id: idMessage, password: input.password ? '' : 'Please enter a password' };
        return (
            <div className="LoginDialogue">
                <h2>Login</h2>
                <LoginDetails
                    dirty={dirty}
                    error={error}
                    onChange={(event) => this.handleChange(event)}
                    onBlur={(event) => this.handleBlur(event)}
                    onEnter={(evt) => this.handleLoginClick()}
                />
                <LoginButton onClick={() => this.handleLoginClick()} isDisabled={loginDisabled} />
                <a href={Routes.reset} className="centeredLink" onClick={(evt) => this.handleRegisterClick(evt)}>Forgot password</a>
            </div>
        );
    }


    getInput() {
        return {
            id: this.state.id,
            password: this.state.password
        };
    }


    handleLoginClick = async () => {
        const input = this.getInput();
        if (input.id && input.password) {
          await this.Login(input.id, input.password);
        } else {
            this.showInvalidLogin('Please enter ID and password');
        }
    }

    handleRegisterClick(evt: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        evt.preventDefault();
        navigate(Routes.reset);
    }

    Login =  async (userId: string, password: string) => {
        try {
            const loginResult = await LoginService.passwordLogin(userId, password);
            if (TypeCheck.isAuthResponse(loginResult)) {
                this.props.store.set('id')(userId);
                this.props.store.set('user')(loginResult);
                this.setState({ nextRoute: Routes.employees });
            } else if (TypeCheck.isLoginResponse(loginResult)) {
                this.props.store.set('id')(userId);
                if (loginResult.passwordReset) {
                    this.setState({ nextRoute: Routes.password });
                } else {
                    this.setState({ nextRoute: Routes.token });
                    if (!loginResult.success) {
                        this.showInvalidLogin();
                    } else {
                        const tokenLength = configHelper.tokenLength();
                        Toast.info(this.props.store, `Check your email for the ${tokenLength} digit token.`);
                    }
                }
            } else {
                this.setState({ nextRoute: null });
                this.showInvalidLogin('Authentication failure');
            }
        } catch (ex) {
            if (TypeCheck.isError(ex)) {
                this.setState({ nextRoute: null });
                this.showInvalidLogin(`Problem logging in - ${ex.message}`, ex.name);
            } else {
                const result = Utils.guardedTrim(ex);
                this.setState({ nextRoute: null });
                this.showInvalidLogin(`Problem logging in - ${result}`);
            }
        }
    }


    showInvalidLogin(reason?: string, severity?: string) {
        Toast.set(this.props.store, severity || 'error', reason || 'Problem logging in - please contact HBF');
    }

    handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        const name = Utils.guardedTrim(event.target.name);
        const value = event.target.value;
        if (!name || !(name in this.state)) {
            return;
        }
        this.setState((state) => {
            const newState: any = {[name]: value};
            return newState;
        });
    }

    handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const name = Utils.guardedTrim(event.target.name);
        if (!name || !(name in this.state)) {
            return;
        }
        if (event.target.value) {
            this.setState((state) => {
                const newState = { dirty: { ...state.dirty, [name]: true } };
                return newState;
            });
        }
    }
}

const StatefulLoginDialog = AppState.withStore(LoginDialogue);

export default StatefulLoginDialog;
