import React from 'react';
import AppState, { AppStateProps } from '../../state/AppState';
import { AuthResponse } from '../../models/AuthResponse';
import TokenLoginDetails from './TokenLoginDetails';
import TokenLoginButton from './TokenLoginButton';
import { Routes } from '../../helpers/routes';
import { Utils } from '../../helpers/utils';
import { LoginService } from '../../services/LoginService';
import {  Redirect } from '@reach/router';
import Toast from '../../helpers/ToastUtils';
import { TypeCheck } from '../../helpers/typecheck';
import Validate from '../../helpers/validate';

type TokenLoginDialogueProps = AppStateProps & {
    onSuccessfullLogin: (user: AuthResponse) => void;
};
interface TokenLoginDialogueState {
    id: string;
    token: string;
    isValidLogin: boolean;
    dirty: {[name: string]: boolean};
}
class TokenLoginDialogue extends React.Component<TokenLoginDialogueProps, TokenLoginDialogueState> {
    constructor(props: TokenLoginDialogueProps) {
        super(props);
        this.state = {
            id: '',
            token: '',
            isValidLogin: false,
            dirty: { token: false }
        };
    }

    render() {
        const input = this.getInput();
        if (input.id == null) {
            return <Redirect noThrow={true} to={Routes.login} />;
        }
        const user = this.props.store.get('user');
        if (user != null) {
            return <Redirect noThrow={true} to={Routes.employees} />;
        }

        const tokenMessage = Validate.token(input.token);
        const loginDisabled = !!tokenMessage;
        const dirty = this.state.dirty;
        const error = { token: tokenMessage };


        return (
            <div className="LoginDialogue">
                <h2>Token</h2>
                <TokenLoginDetails
                    id={input.id}
                    dirty={dirty}
                    error={error}
                    onChange={(event) => this.handleChange(event)}
                    onBlur={(event) => this.handleBlur(event)}
                    onEnter={() => this.handleLoginClick()}
                />
                <TokenLoginButton onClick={() => this.handleLoginClick()} disabled={loginDisabled} />
            </div>
        );
    }


    getInput() {
        return {
            id: this.props.store.get('id'),
            token: this.state.token
        };
    }

    async handleLoginClick() {
        const input = this.getInput();
        if (input.id && input.token) {
          await this.Login(input.id, input.token);
       } else {
            this.showInvalidLogin();
        }
    }

    async Login(userId: string, token: string) {
        try {
            const result = await LoginService.tokenLogin(userId, token);
            if (TypeCheck.isAuthResponse(result)) {
                this.props.store.set('user')(result);
                this.setState({ isValidLogin: true });
            } else {
                this.setState({ isValidLogin: false });
                this.showInvalidLogin('Authentication failure');
            }
        } catch (ex) {
            this.setState({ isValidLogin: false });
            this.showInvalidLogin();
        }
    }

    showInvalidLogin(reason?: string) {
        Toast.error(this.props.store, reason || 'Invalid token');
    }


    handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            token: event.target.value
        });
    }

    handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const name = Utils.guardedTrim(event.target.name);
        if (!name || !(name in this.state)) {
            return;
        }
        if (event.target.value) {
            this.setState((state) => {
                const newState = { dirty: { ...state.dirty, [name]: true } };
                return newState;
            });
        }
    }
}

const StatefulTokenLoginDialog = AppState.withStore(TokenLoginDialogue);

export default StatefulTokenLoginDialog;
