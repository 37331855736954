import React from 'react';
import Modal from 'react-bootstrap/Modal';
import './SubmitModal.scss';
import HBFButton from '../../components/HBFButton';

interface SubmitModalProps {
    onModalResult: (result: boolean) => void;
}

export default function SubmitModal(props: SubmitModalProps) {
    return (
        <Modal show={true} onHide={() => props.onModalResult(false)} className="SubmitModal">
            <Modal.Dialog>
                <Modal.Header closeButton={true}>
                    <Modal.Title>Submit and Finalise confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        The Submit and Finalise button tells the HBF Corporate Member team that you
                        are{' '}
                        <strong>
                            satisfied with your edits and to process the changes to your corporate
                            membership
                        </strong>
                        .
                    </p>
                    <p>
                        <strong>You will not be able to make any more changes after this</strong>,
                        until the next update cycle.
                    </p>
                    <p>Is this really what you want to do?</p>
                </Modal.Body>
                <Modal.Footer>
                    {' '}
                    <HBFButton color="primary" onClick={() => props.onModalResult(true)}>
                        Yes
                    </HBFButton>
                    <HBFButton color="warning" onClick={() => props.onModalResult(false)}>
                        No
                    </HBFButton>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
}
