import React from 'react';
import './App.scss';
import LoginScreen from './screens/Login/LoginPage';
import TokenLoginScreen from './screens/Token/TokenLoginPage';
import StatefulEmployeesScreen from './screens/Employees/EmployeesScreen';
import Header from './components/Header';
import { Router } from '@reach/router';
import { Routes } from './helpers/routes';
import AppState, { AppStateProps } from './state/AppState';
import { BaseScreenProps } from './models/BaseScreenProps';
import Toast from 'react-bootstrap/Toast';
import ToastBody from 'react-bootstrap/ToastBody';
import config from './config';
import { ToastModel } from './models/ToastModel';
import { LoginService } from './services/LoginService';
import ResetPage from './screens/Reset/ResetPage';
import PasswordPage from './screens/Password/PasswordPage';
import { ContactPage } from './screens/Contact/ContactPage';
import { TypeCheck } from './helpers/typecheck';
import Modal from 'react-bootstrap/Modal';
import HBFButton from './components/HBFButton';


const NotFound = (props: BaseScreenProps) => (
  <div>Sorry, nothing here.</div>
);

interface AppState {
  toasts: (ToastModel|string)[];
  errors: ToastModel[];
}
export class App extends React.Component<AppStateProps, AppState> {

  constructor(props: AppStateProps) {
    super(props);
    this.state = { toasts: [], errors: [] };
    this.props.store.on('toast').subscribe(toast => {
      if (toast == null) {
        return;
      }
      const toasts = this.state.toasts.slice();
      toasts.push(toast);
      if (TypeCheck.isToastModel(toast) && (toast.type === 'error' || toast.type === 'notice')) {
        if (toast.type !== 'error') {
          toast.title = toast.type;
        }
        const errors = this.state.errors.slice();
        errors.push(toast);
        this.setState({toasts, errors});
      } else {
        this.setState({toasts});
      }
    });
  }

  componentDidMount = () => {

    setInterval(async () => {
      try {
        const response = await LoginService.ping();
        this.props.store.set('online')(!!response.success);
      } catch (ex) {
        this.props.store.set('online')(false);
      }
    }, config.pingIntervalMs);

  }

  render() {
    const currentToasts = this.state.toasts;
    const currentErrors = this.state.errors;
    return (
        <div className="App">
          <Header />
          <Router>
            <LoginScreen path={Routes.login} />
            <TokenLoginScreen path={Routes.token} />
            <StatefulEmployeesScreen path={Routes.employees}/>
            <ResetPage path={Routes.reset}/>
            <PasswordPage path={Routes.password}/>
            <ContactPage path={Routes.contact}/>
            <NotFound default={true} />
          </Router>
          <div className={'toasts' + (currentToasts.length > 0 ? ' active' : '')}>
          {currentToasts.map((x: any, i) => (
            <Toast key={i} onClose={() => this.removeToast(i)} 
              delay={x.delayMs || (x.type === 'error' ? config.toastErrorDelayMs : config.toastDelayMs)} 
              autohide={x.delayMs === 0 ? false : true}>
                <ToastBody><span className={x.type || 'info'}>{x.message || x}</span></ToastBody>
            </Toast>)
          )}
          </div>
          {currentErrors.map((x: any, i) => (
            <Modal key={i} show={true} onHide={() => this.dialogClose(i)} dialogClassName={x.type}>
              <Modal.Dialog>
                <Modal.Header closeButton={true}>
                  <Modal.Title>{x.title || 'Error'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{x.message}</Modal.Body>
                <Modal.Footer>
                  <HBFButton onClick={() => this.dialogClose(i)}>Close</HBFButton>
                </Modal.Footer>              
              </Modal.Dialog>
            </Modal>
          ))}
        </div>
    );
  }

  removeToast = (toastIndex: number) => {
    this.setState((state) => {
      const toasts = state.toasts;
      toasts.splice(toastIndex, 1);
      return { toasts };
    });
  }


  dialogClose = (errorIndex: number) => {
    this.setState((state) => {
      const errors = state.errors;
      errors.splice(errorIndex, 1);
      return { errors };
    });
  }

}

export const StatefulApp = AppState.withStore(App);

class Root extends React.Component {
  render() {
    return (
      <AppState.Container>
        <StatefulApp/>
      </AppState.Container>
    );
  }
}


export default Root;
