import React, { useState } from 'react';
import HBFButton from '../../components/HBFButton';
import SubmitModal from './SubmitModal';

interface EmployeesButtonProps {
    alignment: 'left' | 'right';
    hasOpenStatement: boolean;
    uploading: boolean;
    uploadDisabled?: boolean;
    onUploadClick: (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    reloadVisible: boolean;
    reloadLoading: boolean;
    onReloadClick: (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    saveDraftVisible: boolean;
    saveDraftDisabled: boolean;
    saveDraftLoading: boolean;
    onSaveDraftClick: (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    submitLoading: boolean;
    onSubmitClick: () => void;
    onLogoutClick: (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export default function EmployeesButtons(props: EmployeesButtonProps) {
    let continueButtons;

    const [submitting, setSubmitting] = useState(false);

    function handleSubmitClick(evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        setSubmitting(true);
    }

    function handleSubmitModalResult(yes: boolean) {
        setSubmitting(false);
        if (yes) {
            props.onSubmitClick();
        }
    }

    if (props.hasOpenStatement) {
        continueButtons = (
            <div className="endButtons">
                <HBFButton
                    color="secondary"
                    onClick={props.onUploadClick}
                    disabled={props.uploadDisabled}
                    isLoading={props.uploading}
                >
                    Upload file
                </HBFButton>
                {props.reloadVisible && (
                    <HBFButton
                        color="secondary"
                        isLoading={props.reloadLoading}
                        onClick={props.onReloadClick}
                    >
                        Reload
                    </HBFButton>
                )}
                {props.saveDraftVisible && (
                    <HBFButton
                        color="secondary"
                        disabled={props.saveDraftDisabled}
                        isLoading={props.saveDraftLoading}
                        onClick={props.onSaveDraftClick}
                    >
                        Save draft
                    </HBFButton>
                )}
                <HBFButton isLoading={props.submitLoading} onClick={handleSubmitClick}>
                    Submit and Finalise
                </HBFButton>
                {submitting && <SubmitModal onModalResult={handleSubmitModalResult} />}
            </div>
        );
    }

    return (
        <div className={'EmployeesButtons ' + props.alignment}>
            <div className="startButtons">
                <HBFButton color="danger" onClick={props.onLogoutClick}>
                    Logout
                </HBFButton>
            </div>
            {continueButtons}
        </div>
    );
}
