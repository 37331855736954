import React from 'react';
import Modal from 'react-bootstrap/Modal';
import HBFButton from '../../components/HBFButton';


interface UploadFileModalProps {
    show: boolean;
    onHideClick: (event: React.MouseEvent<Element, MouseEvent>) => void;
    onUploadClick: (event: React.MouseEvent<Element, MouseEvent>) => void;
    onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    fileAttached: boolean;
}


export default function UploadFileModal(props: UploadFileModalProps) {

    return (
        <Modal show={props.show} onHide={props.onHideClick}>
            <Modal.Header closeButton={true}>
                <Modal.Title>Upload file</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Upload an updated CSV file.<br />
                <input type="file" onChange={(event) => props.onFileChange(event)} />
            </Modal.Body>
            <Modal.Footer>
                <HBFButton color="secondary" onClick={props.onHideClick}>Cancel</HBFButton>
                <HBFButton color="primary" onClick={props.onUploadClick} disabled={!props.fileAttached}>Upload</HBFButton>
            </Modal.Footer>
        </Modal>
    );
}
