function guardedTrim(obj: any) {
    if (obj == null) {
        return '';
    }
    return obj.toString().trim();
}
function ifNull<T>(obj: T | undefined | null, defaultValue: T) {
    if (obj == null) {
        return defaultValue;
    }
    return obj;   
}
function addCacheBuster(url: string) {
    const delimiter = url.indexOf('?') > -1 ? '&' : '?';
    const buster = (new Date()).valueOf();
    return `${url}${delimiter}_=${buster}`;
}

export const Utils = {
    addCacheBuster,
    guardedTrim,
    guardedTrimLowerCase: (obj: any) => {
        return guardedTrim(obj).toLowerCase();
    },
    isNullOrWhiteSpace: (str: string | undefined | null) => {
        return str == null || /^\s*$/.test(str);
    },
    ifNull
};
