import MessageResponse from '../models/MessageResponse';
import { FetchService, BadResponse } from './FetchService';


async function requestReset(memberNumber: string): Promise<MessageResponse | BadResponse> {
    const payload = { memberNumber };
    const jsonData = JSON.stringify(payload);

    return fetch(`${window.corpPortalConfig.serviceUrl}/api/Reset`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: jsonData
    })
    .then(response => FetchService.guardedParse<MessageResponse>(response, r => r.json()));
}

async function setNewPassword(memberNumber: string, resetCode: string, newPassword: string): Promise<MessageResponse | BadResponse> {
    const payload = { memberNumber, resetCode, newPassword };
    const jsonData = JSON.stringify(payload);

    return fetch(`${window.corpPortalConfig.serviceUrl}/api/Reset/SetPassword`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: jsonData
    })
    .then(response => FetchService.guardedParse<MessageResponse>(response, r => r.json()));
}


const ResetService = {
    requestReset,
    setNewPassword
};

export default ResetService;

