import React from 'react';

interface MessageProps {
    text?: string;
    children?: any;
}

export default function Message(props: MessageProps) {
    return (
        <div className="message">
            { props.children || props.text }
        </div>
    );
}
