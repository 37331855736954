import './ResetPage.scss';
import React from 'react';
import { BaseScreenProps } from '../../models/BaseScreenProps';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import HBFButton from '../../components/HBFButton';
import ResetService from '../../services/ResetService';
import { Utils } from '../../helpers/utils';
import Toast from '../../helpers/ToastUtils';
import AppState, { AppStateProps } from '../../state/AppState';
import { Redirect } from '@reach/router';
import { Routes } from '../../helpers/routes';
import { TypeCheck } from '../../helpers/typecheck';
import Validate from '../../helpers/validate';


type ResetPageProps = AppStateProps & BaseScreenProps;
interface ResetPageState {
    id: string;
    resetSuccess: boolean;
    dirty: {[name: string]: boolean};
}
class ResetPage extends React.Component<ResetPageProps, ResetPageState> {
    constructor(props: ResetPageProps) {
        super(props);
        this.state = {
            id: '',
            resetSuccess: false,
            dirty: {
                id: false
            }
        };
    }


    render() {
        if (this.state.resetSuccess) {
            return <Redirect noThrow={true} to={Routes.password} />;
        }
        const memberNumberMessage = Validate.memberNumber(this.state.id);
        return (
            <div className="main ResetPage">
                <h2>Reset your password</h2>
                <Alert variant="info">Please enter your company ID below.
                    You will then recieve an email with a code to allow you to reset your password.</Alert>
                <Form>
                    <Form.Group controlId="memberNumber">
                        <Form.Label>Company ID</Form.Label>
                        <Form.Control type="text" placeholder="Enter company ID"
                            name="id"
                            isValid={this.state.dirty.id && !memberNumberMessage}
                            isInvalid={this.state.dirty.id && !!memberNumberMessage}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            onKeyPress={this.handleKeyPress}
                            autoComplete="off"
                            autoFocus={true}
                        />
                        <Form.Control.Feedback type="invalid">
                            {memberNumberMessage}
                        </Form.Control.Feedback>
                    </Form.Group>
                 </Form>
                <HBFButton onClick={this.handleResetClick} disabled={!this.state.id}>Reset</HBFButton>
            </div>
        );
    }


    handleKeyPress = async (evt: React.KeyboardEvent<HTMLInputElement>) => {
        if (evt.key === 'Enter') {
            evt.preventDefault();
            await this.handleResetClick();
        }
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const id = event.target.value;
        this.setState({id});
    }


    handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const name = Utils.guardedTrim(event.target.name);
        if (!name || !(name in this.state)) {
            return;
        }
        if (event.target.value) {
            this.setState((state) => {
                const newState = { dirty: { ...state.dirty, [name]: true } };
                return newState;
            });
        }
    }


    handleResetClick = async () => {
        const id = Utils.guardedTrim(this.state.id);
        if (!id) {
            this.showError('Please enter your membership number.');
        }
        try {
            const result = await ResetService.requestReset(this.state.id);
            if (!TypeCheck.isMessageResponse(result)) {
                throw result;
            }
            if (!result.success) {
                this.showError(result.message);
            } else {
                this.props.store.set('id')(id);
                this.setState({resetSuccess: true});
            }
        } catch (ex) {
            const error = Utils.guardedTrim(ex);
            this.showError(`Error from server: ${error}`);
        }
    }


    showError(reason?: string) {
        Toast.error(this.props.store, reason || 'Problem with password reset');
    }

}

const StatefulResetPage = AppState.withStore(ResetPage);

export default StatefulResetPage;
