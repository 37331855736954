import React from 'react';
import { BaseScreenProps } from '../../models/BaseScreenProps';

type ContactPageProps = BaseScreenProps;

export function ContactPage(props: ContactPageProps) {
    return (
        <div className="main ContactPage">
            <h2>Contact us</h2>
            <p>
                For support, please call us at <a href="tel:1300736427">1300 736 427</a>, 
                or email <a href="mailto:HBFCorpAcct@hbf.com.au">HBFCorpAcct@hbf.com.au</a>.
            </p>
        </div>
    );
}
