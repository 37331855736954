import React from 'react';
import Form from 'react-bootstrap/Form';

interface TokenLoginDetailsProps {
    id: string;
    dirty: {[name: string]: boolean};
    error: {[name: string]: string};
    onChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur: (evt: React.FocusEvent<HTMLInputElement>) => void;
    onEnter: (evt: React.KeyboardEvent<HTMLInputElement>) => void;
}

export default function LoginDetails(props: TokenLoginDetailsProps) {

    function handleKeyPress(evt: React.KeyboardEvent<HTMLInputElement>) {
        if (evt.key === 'Enter') {
            evt.preventDefault();
            props.onEnter(evt);
        }
    }


    return (
        <Form>
            <Form.Group controlId="formId">
                <Form.Label>ID</Form.Label>
                <Form.Control type="text" autoComplete="off" value={props.id} readOnly={true} />
            </Form.Group>            
            <Form.Group controlId="formToken">
                <Form.Label>Token</Form.Label>
                <Form.Control 
                    name="token"
                    type="password" 
                    placeholder="Token"
                    isValid={props.dirty.token && !props.error.token}
                    isInvalid={props.dirty.token && !!props.error.token}
                    onChange={props.onChange} 
                    onBlur={props.onBlur}
                    onKeyPress={handleKeyPress}
                    autoFocus={true}
                />
                <Form.Control.Feedback type="invalid">
                    {props.error.token}
                </Form.Control.Feedback> 
            </Form.Group>
        </Form>
    );
}
