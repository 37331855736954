import MessageResponse from '../models/MessageResponse';
import { FetchService, BadResponse } from './FetchService';
import { AuthResponse } from '../models/AuthResponse';


function tokenLogin(userId: string, token: string): Promise<AuthResponse | BadResponse> {
    const tokenLoginModel = {
        MemberNumber: userId,
        Token: token
    };
    const jsonData = JSON.stringify(tokenLoginModel);
    return fetch(`${window.corpPortalConfig.serviceUrl}/api/Account/TokenLogin/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: jsonData
    })
    .then(response => FetchService.guardedParse<AuthResponse>(response, r => r.json()));
}

function passwordLogin(userId: string, password: string): Promise<MessageResponse | BadResponse> {
    const loginModel = {
        MemberNumber: userId,
        Password: password          
    };
    const jsonData = JSON.stringify(loginModel);
    return fetch(`${window.corpPortalConfig.serviceUrl}/api/Account/Login/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: jsonData
    })
    .then(response => FetchService.guardedParse<MessageResponse>(response, r => r.json()));
}

async function ping(): Promise<MessageResponse> {
    return fetch(`${window.corpPortalConfig.serviceUrl}/api/Account/Ping`)
        .then(response => response.json());
}

async function logout(memberNumber: string) { 
    const logoutModel = {
        MemberNumber: memberNumber
    };
    const jsonData = JSON.stringify(logoutModel);
    await fetch(`${window.corpPortalConfig.serviceUrl}/api/Account/Logout/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: jsonData
    });
}


export const LoginService = {
    logout,
    passwordLogin,
    ping,
    tokenLogin
};

