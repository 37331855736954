import { CompanyModel } from '../../models/CompanyModel';
import React from 'react';

interface CompanyInfoProps {
    company: CompanyModel;
}

export default function CompanyInfo(props: CompanyInfoProps) {
    return (
        <div className="planInfo">
            <b>HBF Corporate Plan Employee Validation Statement<br />
                {props.company.name} - {props.company.id} <br />
                October 2019</b><br />
        </div>      
    );
}
