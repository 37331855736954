import { FetchService } from './FetchService';
import { EmployeeList } from '../models/EmployeeList';
import MessageResponse from '../models/MessageResponse';
import SaveEmployeeListRequest from '../models/SaveEmployeeListRequest';
import { Utils } from '../helpers/utils';


async function getEmployees(): Promise<EmployeeList> {
    const url = Utils.addCacheBuster(`${window.corpPortalConfig.serviceUrl}/api/Employees`);
    const response = await FetchService.fetchWithCredentials(url, {cache: 'no-store'})
        .then(r => FetchService.guardedParse<EmployeeList>(r));
    if (typeof response === 'string') {
        throw response;
    }
    return response;
}

async function fileName(): Promise<MessageResponse> {
    const response = 
        await FetchService.fetchWithCredentials(`${window.corpPortalConfig.serviceUrl}/api/Employees/filename`, {cache: 'no-store'})
        .then(r => FetchService.guardedParse<MessageResponse>(r));
    if (typeof response === 'string') {
        throw response;
    }
    return response;
}

async function downloadFile(): Promise<Blob> {
    const response = 
        await FetchService.fetchWithCredentials(`${window.corpPortalConfig.serviceUrl}/api/Employees/file`, {cache: 'no-store'})
        .then(resp => FetchService.guardedParse<Blob>(resp, r => r.blob()));
    if (typeof response === 'string') {
        throw response;
    }
    return response;
}

function postAttachedFile(formData: FormData): Promise<any> {
    return FetchService.fetchWithCredentials(`${window.corpPortalConfig.serviceUrl}/api/Employees/file`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json'
        },
        body: formData
    })
    .then(response => FetchService.guardedParse(response));
}


function postEmployees(data: SaveEmployeeListRequest): Promise<any> {
    const jsonData = JSON.stringify(data);

    return FetchService.fetchWithCredentials(`${window.corpPortalConfig.serviceUrl}/api/Employees`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: jsonData
    })
    .then(response => FetchService.guardedParse(response));
}


export const EmployeeService = {
    downloadFile,
    fileName,
    getEmployees,
    postAttachedFile,
    postEmployees
};

