import { AuthResponse } from '../models/AuthResponse';
import { TypeCheck } from '../helpers/typecheck';

const UserKey = 'CPUSER';

function storeUser(user: AuthResponse) {
    const userStr = JSON.stringify(user);
    sessionStorage.setItem(UserKey, userStr);
}

function currentUser(): AuthResponse | null {
    const userStr = sessionStorage.getItem(UserKey);
    if (userStr == null) {
        return null;
    }
    return JSON.parse(userStr);
}

function clearUser() {
    sessionStorage.removeItem(UserKey);
}


async function refreshAuth() {
    const user = currentUser();
    if (user == null) {
        throw new Error(`Can't refresh, no current user.`);
    }
    const request = { token: user.token, refreshToken: user.refreshToken };
    const refreshed = await fetch(`${window.corpPortalConfig.serviceUrl}/api/Account/RefreshJWT`, {
        method: 'POST',
        body: JSON.stringify(request),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }).then(response => response.json());

    if (!TypeCheck.isAuthResponse(refreshed)) {
        throw new Error(`Invalid response`);
    } 
    storeUser(refreshed);
    return refreshed;
}


export const AuthService = {
    clearUser,
    currentUser,
    refreshAuth,
    storeUser
};
