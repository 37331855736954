import React from 'react';
import './EmployeesScreen.scss';
import { CompanyModel } from '../../models/CompanyModel';
import { BaseScreenProps } from '../../models/BaseScreenProps';
import { EmployeeModel } from '../../models/EmployeeModel';
import { Utils } from '../../helpers/utils';
import { EmployeeService } from '../../services/EmployeeService';
import { TypeCheck } from '../../helpers/typecheck';
import { Redirect } from '@reach/router';
import { BadResponse } from '../../services/FetchService';
import { Routes } from '../../helpers/routes';
import AppState, { AppStateProps } from '../../state/AppState';
import SaveEmployeeListRequest from '../../models/SaveEmployeeListRequest';
import { DebounceInput } from 'react-debounce-input';
import config from '../../config';
import CompanyInfo from './CompanyInfo';
import TopButtons from './TopButtons';
import Message from './Message';
import EmployeesTable from './EmployeesTable';
import UploadFileModal from './UploadFileModal';
import EmployeesButtons from './EmployeesButtons';
import Toast from '../../helpers/ToastUtils';
import ToastUtils from '../../helpers/ToastUtils';
import configHelper from '../../helpers/configHelper';
import AutoSave from '../../helpers/autosave';
import logout from '../../helpers/logout';


type EmployeesScreenProps = AppStateProps & BaseScreenProps;

interface EmployeesScreenState {
    employeesPage: EmployeeModel[];
    maxFilteredEmployees: number;
    firstLoad: boolean;
    isLarge: boolean;
    hasOpenStatement: boolean;
    searchQuery: string;
    company: CompanyModel;
    file: string | Blob | null;
    showUploadModal: boolean;
    badLoad: BadResponse;
    saveDraftLoading: boolean;
    submitLoading: boolean;
    isDirty: boolean;
    uploadingFile: boolean;
    reloadLoading: boolean;
    isDownloaded: boolean;
    downloading: boolean;
    pageNumber: number;
    pageSize: number;
    isSubmitted: boolean;
}


class EmployeesScreen extends React.Component<EmployeesScreenProps, EmployeesScreenState> {

    private employees: EmployeeModel[] = [];

    constructor(props: EmployeesScreenProps) {
        super(props);
        this.state = {
            employeesPage: [],
            maxFilteredEmployees: 0,
            firstLoad: true,
            isLarge: false,
            hasOpenStatement: false,
            searchQuery: '',
            company: {
                id: '',
                name: '',
            },
            file: null,
            showUploadModal: false,
            badLoad: BadResponse.OK,
            saveDraftLoading: false,
            submitLoading: false,
            isSubmitted: false,
            isDirty: false,
            uploadingFile: false,
            reloadLoading: false,
            isDownloaded: false,
            downloading: false,
            pageNumber: 0,
            pageSize: 100
        };
        AutoSave.loggedIn();
        AutoSave.subscribeLogoutCheck(this.onLogout);
    }

    autosave = AutoSave.debounce(async () => {
        try {
            if (!this.state.isDirty) {
                return;
            }
            this.setState({saveDraftLoading: true});
            await this.postEmployees(false, false);
        } catch (ex) {
            // exception is caught in postEmployees
        } finally {
            setTimeout(() => this.setState({saveDraftLoading: false}), config.uiLoadingTimeoutMs);
        }
    }, configHelper.autoSaveIdleMs());

    onLogout = async () => {
        if (!this.state.isDirty) {
            return;
        }
        await this.postEmployees(false, true);
    }

    render() {
        const badLoad = Utils.guardedTrim(this.state.badLoad);
        const user = this.props.store.get('user');

        if (user == null || badLoad.indexOf(BadResponse.Unauthorised) > -1) {
            return (
                <Redirect to={Routes.login} noThrow={true} />
            );
        }

        let component;

        if (this.state.firstLoad) {
            component = <Message text="Loading..." />;
        } else if (this.state.isSubmitted) {
            component = (
                <Message>
                    Your statement has been submitted for processing by our Corporate team.<br />
                    There will be no information available for editing until the next processing period.<br />
                    If this was in error, please <a href={Routes.contact}>contact us</a>.
                </Message>
            );
        } else if (!this.state.hasOpenStatement) {
            component = <Message text="You don't currently have any statements to update." />;
        } else if (this.state.isDownloaded) {
            component = (
                <>
                    <CompanyInfo company={this.state.company} />
                    <TopButtons downloadLoading={false} downloadDisabled={this.state.isDownloaded}
                        onDownloadClick={() => this.handleDownloadClick()} />
                    <Message text="You have this file downloaded for editing on your computer." />
                </>
            );
        } else if (this.state.isLarge) {
            component = (
                <>
                    <CompanyInfo company={this.state.company} />
                    <TopButtons downloadLoading={false} downloadDisabled={this.state.isDownloaded}
                        onDownloadClick={() => this.handleDownloadClick()} />
                    <Message text="This file is too large to edit online. Please download and edit on your computer." />
                </>
            );
        } else {
            const maxPage = Math.floor(this.state.maxFilteredEmployees / this.state.pageSize);
            component =
                <>
                    <CompanyInfo company={this.state.company} />
                    <TopButtons downloadLoading={false} downloadDisabled={this.state.isDownloaded}
                        onDownloadClick={() => this.handleDownloadClick()} />
                    <div className="searchBox">
                        Enter search term:
                        <DebounceInput
                            minLength={0}
                            debounceTimeout={500}
                            onChange={event => this.handleSearchQueryChange(event)}
                        />
                    </div>
                    <div className="pager">
                        <span className="pager__info">Page {this.state.pageNumber + 1} of {maxPage + 1}</span>
                        {maxPage > 0 && (
                            <span className="pager__controls">
                                <button title="First page" onClick={event => this.page(0)}
                                    disabled={this.state.pageNumber === 0}>&lt;&lt;</button>
                                <button title="Previous page" onClick={event => this.pagePrev()}
                                    disabled={this.state.pageNumber === 0}>&lt;</button>
                                <button title="Next page" onClick={event => this.pageNext()}
                                    disabled={this.state.pageNumber === maxPage}>&gt;</button>
                                <button title="Last page" onClick={event => this.page(maxPage)}
                                    disabled={this.state.pageNumber === maxPage}>&gt;&gt;</button>
                            </span>
                        )}
                        <span className="pager__info">Total employess {this.employees.length}
                            {this.state.searchQuery && (
                                <>, {this.state.maxFilteredEmployees} matching</>
                            )}
                        </span>
                    </div>
                    <div className="tableContainer">
                        <EmployeesTable
                            employees={this.state.employeesPage}
                            onIsCurrentEmployeeChange={(event, id) => this.handleEmployeeIsCurrentEmployeeChange(event, id)}
                            onCommentChange={(event, id) => this.handleEmployeeCommentChange(event, id)}
                        />
                    </div>
                </>;
        }

        return (
            <div className="EmployeesPage">
                <UploadFileModal
                    show={this.state.showUploadModal}
                    onHideClick={() => this.handleModalHide()}
                    onFileChange={(event) => this.handleFileChange(event)}
                    onUploadClick={() => this.handleUploadConfirmed()}
                    fileAttached={this.state.file !== null}
                />
                {component}
                <EmployeesButtons
                    alignment={this.state.isLarge ? 'left' : 'right'}
                    hasOpenStatement={this.state.hasOpenStatement}
                    onLogoutClick={() => this.handleLogoutClick()}
                    saveDraftVisible={!this.state.isLarge}
                    saveDraftLoading={this.state.saveDraftLoading}
                    saveDraftDisabled={!this.state.isDirty}
                    onSaveDraftClick={() => this.handleSaveDraftClick()}
                    submitLoading={this.state.submitLoading}
                    onSubmitClick={() => this.handleSubmitClick()}
                    uploading={false}
                    uploadDisabled={!this.state.isDownloaded}
                    onUploadClick={() => this.handleUploadClick()}
                    reloadVisible={!this.state.isLarge}
                    reloadLoading={this.state.reloadLoading}
                    onReloadClick={() => this.handleReloadClick()}
                />
            </div>
        );
    }

    componentDidMount() {
        this.getEmployees();
    }

    handleDownloadClick = async () => {
        let isDownloaded = this.state.isDownloaded;
        try {
            this.setState({downloading: true});

            // This bit of code based on
            // https://blog.jayway.com/2017/07/13/open-pdf-downloaded-api-javascript/

            const fileName = await EmployeeService.fileName();
            const fileBlob = await EmployeeService.downloadFile();

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(fileBlob);
                return;
            }
            // For other browsers:
            // Create link pointing to ObjectURL containing the blob.
            const data = window.URL.createObjectURL(fileBlob);
            const link = document.createElement('a');
            link.href = data;
            link.download = fileName.message || `${this.state.company.id}.csv`;
            setTimeout(() => {
                link.click();
                // Firefox, necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data);
            }, 100);

            isDownloaded = true;

            ToastUtils.info(this.props.store, 'Downloaded file successfully');
        } catch (ex) {
            console.error(ex);
            this.setState({badLoad: Utils.guardedTrim(ex)});
        } finally {
            setTimeout(() => this.setState({downloading: false, isDownloaded}), config.uiLoadingTimeoutMs);
        }
    }

    handleModalHide = () => {
        this.setState({
            showUploadModal: false,
            file: null
        });
    }

    handleSearchQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const search = event.target.value;
        this.setState((state) => {
            const filteredState = this.filterEmployeesPage(this.employees, state, null, search);
            return filteredState;
        });
    }

    async getEmployees() {
        try {
            const data = await EmployeeService.getEmployees();
            const user = this.props.store.get('user');
            if (!TypeCheck.isAuthResponse(user)) {
                throw BadResponse.Unauthorised;
            }
            this.employees = (data.isLarge || data.noFile) ? [] : data.employees;
            if (data.noFile) {
                this.setState((state) => {
                    return {
                        firstLoad: false,
                        hasOpenStatement: false,
                    };
                });
            } else {
                this.setState((state) => {
                    const filteredState = this.filterEmployeesPage(this.employees, state, 0, '');
                    return {
                        ...filteredState,
                        firstLoad: false,
                        isLarge: data.isLarge,
                        isDownloaded: data.isDownloaded,
                        hasOpenStatement: true,
                        company: {
                            id: user.memberNumber,
                            name: data.companyName
                        },
                        pageNumber: 0
                    };
                });
            }
        } catch (ex) {
            console.error(ex);
            const badLoad = Utils.guardedTrim(ex);
            if (badLoad.indexOf(BadResponse.Unauthorised) > -1) {
                this.props.store.set('user')(null);
            }
            this.setState({badLoad});
            Toast.error(this.props.store, `Sorry, there was a problem loading your data: ${badLoad}`);
        }
    }

    handleLogoutClick = async () => {
        try {
            await logout(this.props.store);
        } catch (ex) {
            console.error('handleLogoutClick ERROR', ex);
        } finally {
            this.setState({badLoad: BadResponse.Unauthorised});
        }
    }

    handleSubmitClick = async () => {
        try {
            this.setState({submitLoading: true});
            await this.postEmployees(true, false);
            Toast.info(this.props.store, 'Submitted file OK.');
            this.setState({isSubmitted: true});
        } catch (ex) {
            // exception is caught in postEmployees
            this.setState({isSubmitted: false});
        } finally {
            setTimeout(() => this.setState({submitLoading: false}), config.uiLoadingTimeoutMs);
        }
    }

    handleSaveDraftClick = async () => {
        try {
            this.setState({saveDraftLoading: true});
            await this.postEmployees(false, false);
            Toast.info(this.props.store, 'Saved file OK.');
        } catch (ex) {
            // exception is caught in postEmployees
        } finally {
            setTimeout(() => this.setState({saveDraftLoading: false}), config.uiLoadingTimeoutMs);
        }
    }

    handleEmployeeCommentChange = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
        const comment = event.target.value;
        this.setState(
            (state) => {
                const index = this.employees.findIndex(x => x.id === id);
                this.employees[index].comments = comment;
                const newState = this.filterEmployeesPage(this.employees, state);
                return {
                    ...newState,
                    isDirty: true
                };
            },
            this.autosave
        );
    }

    handleEmployeeIsCurrentEmployeeChange = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
        const checked = event.target.checked;
        this.setState(
            (state) => {
                const index = this.employees.findIndex(x => x.id === id);
                this.employees[index].employeeYN = checked;
                const newState = this.filterEmployeesPage(this.employees, state);
                return {
                    ...newState,
                    isDirty: true
                };
            },
            this.autosave
        );
    }

    handleUploadClick = () => {
        this.setState({showUploadModal: true});
    }

    handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let file = null;
        if (event && event.target && event.target.files) {
            file = event.target.files[0];
        }
        if (file == null) {
            return;
        }
        this.setState({file});
    }

    handleUploadConfirmed = async () => {
        let isDownloaded = this.state.isDownloaded;
        try {
            this.setState({uploadingFile: true});
            await this.postAttachedFile();
            this.setState({showUploadModal: false});
            isDownloaded = false;
            ToastUtils.info(this.props.store, 'Uploaded file successfully');
            await this.getEmployees();
        } catch (ex) {
            console.error(ex);
            this.setState({badLoad: Utils.guardedTrim(ex)});
        } finally {
            setTimeout(() => this.setState({uploadingFile: false, isDownloaded}), config.uiLoadingTimeoutMs);
        }
    }


    handleReloadClick = () => {
        try {
            this.setState({reloadLoading: true});
            this.getEmployees();
            Toast.info(this.props.store, 'Reloaded file OK.');
        } catch (ex) {
            // exception is caught in postEmployees
        } finally {
            setTimeout(() => this.setState({reloadLoading: false}), config.uiLoadingTimeoutMs);
        }
    }


    async postAttachedFile() {
        const file = this.state.file;
        if (file == null) {
            return;
        }
        const formData = new FormData();
        formData.append(`${this.state.company.id}_uploaded.csv`, file);

        try {
            await EmployeeService.postAttachedFile(formData);
        } catch (ex) {
            console.error(ex);
            this.setState({badLoad: Utils.guardedTrim(ex)});
        }

    }

    // This is the main "save" method.
    // isFinal - "submits" the file for processing by HBF corporate member team.
    // loggingOut - flag used to avoid state updates when logging out as this causes React errors and memory leaks.
    async postEmployees(isFinal: boolean, loggingOut: boolean) {
        const employeesToSave = this.state.isDirty ? this.employees : [];
        const employeeList: SaveEmployeeListRequest = {
            employees: employeesToSave,
            isFinal,
            isChanged: this.state.isDirty
        };

        try {
            await EmployeeService.postEmployees(employeeList);
            if (isFinal) {
                this.clearEmployees();
            }
        } catch (ex) {
            console.error(ex);
            if (!loggingOut) {
                const badLoad = Utils.guardedTrim(ex);
                this.setState({badLoad});
                Toast.error(this.props.store, badLoad);
            }
            throw ex;
        } finally {
            if (!loggingOut) {
                this.setState({isDirty: false});
            }
        }

    }

    clearEmployees() {
        this.employees = [];
        this.setState({
            employeesPage: [],
            isLarge: false,
            hasOpenStatement: false,
            searchQuery: '',
            company: {
                id: '',
                name: '',
            },
            isDirty: false
        });
    }


    page(n: number) {
        const maxPage = Math.floor(this.state.maxFilteredEmployees / this.state.pageSize);
        if (n >= 0 && n <= maxPage) {
             this.setState((state) => {
                const filteredState = this.filterEmployeesPage(this.employees, state, n);
                return filteredState;
            });
        }
    }


    pagePrev() {
        this.page(this.state.pageNumber - 1);
    }

    pageNext() {
        this.page(this.state.pageNumber + 1);
    }


    filterEmployeesPage(employees: EmployeeModel[], state: EmployeesScreenState, newPage?: number | null, searchString?: string | null) {

        let pageSource: EmployeeModel[] = employees;

        const searchQuery = searchString != null ? Utils.guardedTrimLowerCase(searchString) : state.searchQuery;

        if (searchQuery) {
            pageSource = employees.filter(x => {
                return Utils.guardedTrimLowerCase(x.payrollID).includes(searchQuery)
                    || Utils.guardedTrimLowerCase(x.firstName).includes(searchQuery)
                    || Utils.guardedTrimLowerCase(x.lastName).includes(searchQuery)
                    || Utils.guardedTrimLowerCase(x.employeeEmail).includes(searchQuery)
                    || Utils.guardedTrim(x.comments).toLowerCase().includes(searchQuery);
            });
        }

        const maxFilteredEmployees = pageSource.length;
        const maxPage = Math.floor(maxFilteredEmployees / state.pageSize);

        let pageNumber = Utils.ifNull(newPage, state.pageNumber);
        pageNumber = pageNumber > maxPage ? maxPage : pageNumber;

        const pageStart = state.pageSize * pageNumber;
        const pageEnd = pageStart + state.pageSize - 1;

        const employeesPage = pageSource.slice(pageStart, pageEnd + 1);


        return {employeesPage, maxFilteredEmployees, searchQuery, pageNumber};
    }


}

const StatefulEmployeesScreen = AppState.withStore(EmployeesScreen);
export default StatefulEmployeesScreen;
