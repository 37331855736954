import React from 'react';
import HBFButton from '../../components/HBFButton';

interface DownloadButtonProps {
    disabled?: boolean;
    isLoading?: boolean;
    onClick: () => void;
}

export default function DownloadButton(props: DownloadButtonProps) {
    return (
        <HBFButton isLoading={props.isLoading} disabled={props.disabled} onClick={props.onClick}>Download file</HBFButton>
    );
}
