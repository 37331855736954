export interface IConfig {
    autoSaveIdleMs: number;
    env: string;
    serviceUrl: string;
    tokenLength: number;
}

declare global {
    interface Window { corpPortalConfig: IConfig; }
}

export default {
    // Add common config values here
    uiLoadingTimeoutMs: 1000,
    toastDelayMs: 4000,
    toastErrorDelayMs: 4000,
    pingIntervalMs: 15000
};
