import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { JL } from 'jsnlog';
import { Utils } from './helpers/utils';
import errors from './helpers/errors';

if (window.corpPortalConfig == null || Utils.guardedTrim(window.corpPortalConfig.serviceUrl).length < 1) {
    throw new Error(errors.NoConfig);
}
JL.setOptions({
    defaultAjaxUrl: `${window.corpPortalConfig.serviceUrl}/api/log`
});
JL().debug('JL initialised OK');

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
